<template>
  <v-container class="pt-0">
    <div
      :style="
        $vuetify.breakpoint.mobile
          ? 'width: 100%; height: 250px'
          : 'width: 100%; height: 350px'
      "
      class="cover pa-0"
    >
      <img
        alt="agentCover"
        src="https://firebasestorage.googleapis.com/v0/b/bayonapp-2021.appspot.com/o/notification20220111211857BAYON-App-Graphic.png?alt=media&token=8dc0d1f9-466b-488e-a73e-1d5b3aae3239"
      />
    </div>
    <v-container
      :style="
        $vuetify.breakpoint.mobile
          ? 'max-width : 96%; margin-top: -45px;'
          : 'max-width: 70%; margin: -45px 0 0 0; !important; padding-left: 100px;'
      "
    >
      <v-row align="center">
        <v-avatar size="130" style="box-shadow: 0 0 15px #ccc">
          <img
            alt="agent"
            v-if="(agent && agent.photo === '') || agent.photo === undefined"
            src="../assets/user.png"
            class="custom-border-style"
          />
          <img
            :alt="agent.name"
            v-else
            :src="(agent && agent.photo) || ''"
            class="custom-border-style"
          />
        </v-avatar>
        <h1 style="font-size: 26px; font-weight: bold; margin-left: 10px">
          {{
            (agent && agent.profile && agent.profile.fullName) ||
            agent.name ||
            ""
          }}
        </h1>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="12"
          :style="$vuetify.breakpoint.mobile ? 'padding-bottom: 0px;' : ''"
        >
          <v-row>
            <v-col cols="4" class="pa-0">
              <p>
                <v-icon>mdi-phone</v-icon>&nbsp; <em>{{ $t("phone") }}</em>
              </p>
              <p v-if="agent.email">
                <v-icon>mdi-email</v-icon>&nbsp; <em>{{ $t("email") }}</em>
              </p>
              <p v-if="agent.address">
                <v-icon>mdi-map-marker</v-icon>&nbsp;
                <em>{{ $t("address") }}</em>
              </p>
            </v-col>
            <v-col cols="8" class="pa-0">
              <p>: {{ agent.phoneNumber }}</p>
              <p v-if="agent.email">: {{ agent.email }}</p>
              <p v-if="agent.address">: {{ agent.address }}</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          :style="$vuetify.breakpoint.mobile ? 'padding-top: 0px;' : ''"
        >
          <v-row>
            <v-col cols="4" class="pa-0">
              <p>
                <v-icon>mdi-domain</v-icon>&nbsp; <em>{{ $t("property") }}</em>
              </p>
              <p>
                <v-icon>mdi-account</v-icon>&nbsp; <em>{{ $t("client") }}</em>
              </p>
            </v-col>
            <v-col cols="8" class="pa-0">
              <p>: {{ agent.totalProperty }}</p>
              <p>: {{ agent.totalClient }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div
      :style="
        $vuetify.breakpoint.mobile
          ? 'width: 100%'
          : 'width: 100%'
      "
      class="pa-0"
    >
      <v-divider></v-divider>
      <h2 class="mt-3 text-center basil--text">{{ $t("agentProperties") }}</h2>
      <v-row v-if="propertiesByAgentId.length > 0">
        <v-col
          cols="6"
          sm="4"
          md="3"
          v-for="d in propertiesByAgentId"
          :key="d._id"
        >
        <property-card :d="d"></property-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="text-align: center">
          <v-pagination
            v-model="page"
            :length="Math.ceil(totalPage / 12)"
            :total-visible="9"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12" md="12" style="text-align: center">
          No Property Found!
        </v-col>
      </v-row>
    </div> 
  </v-container>
</template>
<script>
import MainMixin from "@/mixins/mainMixin";
import propertyCard from '../components/propertyCard.vue';
export default {
  name: "agent-detail",
  mixins: [MainMixin],
  data() {
    return {
      page: 1,
      totalPage: 0,
      searchType: "",
      sortBy: "",
      sortDesc: -1,
      options: {},
      skip: 0,
      limit: 12,
      eager: true,
    };
  },
  components: {propertyCard},
  watch: {
    page(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 1 * (val - 1);
      vm.limit = 12;
      vm.fetchPropertyByAgentId(
        this.skip,
        this.limit,
        this.sortBy,
        this.sortDesc,
        this.$route.params.agent_id
      );
    },
  },
  mounted() {
    this.$jQuery("body").off();
    window.scrollTo(0, 0, "smooth");
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  created() {
    // ================= Get Agent's Property =========================
    this.fetchPropertyByAgentId(
      this.skip,
      this.limit,
      this.sortBy,
      this.sortDesc,
      this.$route.params.agent_id
    );
  },
};
</script>
<style scoped>
.cover {
  border-radius: 0px 0px 15px 15px;
  -webkit-box-shadow: 0 8px 10px -8px black;
  -moz-box-shadow: 0 8px 10px -8px black;
  box-shadow: 0 8px 10px -8px black;
}
.cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0px 0px 15px 15px;
}
.basil--text {
  color: purple;
}
</style>
